.about1-section-bg {
  background-color: rgb(205, 205, 234);
}
.section-more-header {
  color: #dcab10;
}
.categories-header {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.categories-header h2 {
  font-weight: 600;
}
.categories-header p {
  font-weight: 500;
}
