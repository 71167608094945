.v-container .image-container {
  border: 8px solid rgb(232, 232, 232 / 93%);
}
.values-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 65px auto;
}
.value-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.value-image {
  flex: 1;
  width: 200px;
  margin: auto;
}
.v-container > div {
  flex: 1;
}
.v-right {
  gap: 0.5rem;
}
.core-values {
  font-size: 4rem;
  color: #dcab10;
}
.core1-values {
  font-size: 2rem;
  color: #eeeeff;
}
.text-values {
  font-size: 1.6rem;
  color: #fff;
}
.accordion {
  margin-top: 2rem;
  border: none;
}
.accordionItem {
  background: white;
  border: 0.8px solid rgba(128, 128, 128, 0.143);
  border-radius: 8px;
  margin-bottom: 20px;
}
.accordionItem.expanded {
  box-shadow: var(--shadow);
  border-radius: 6px;
}

.accordionButton {
  background: white;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  padding: 10px;
  background: #eeeeff;
  border-radius: 5px;
  font-size: 2rem;
}
.value-heading {
  font-size: 1.7rem;
  font-weight: 500;
  color: #050a30;
}
.value-detail {
  font-size: 1.6rem;
  font-weight: 400;
}
.icon svg {
  fill: var(--blue);
}
.accordionButton .primaryText {
  font-size: 1.1rem;
}
