.terms-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
  }

  .intro {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 30px;

    h2 {
      font-size: 2.3rem;
      font-weight: bold;
      color: #34495e;
      margin-bottom: 15px;
      border-bottom: 2px solid #3498db;
      padding-bottom: 5px;
    }

    p {
      font-size: 1.3rem;
      margin-bottom: 15px;

      strong {
        font-weight: bold;
        color: #e74c3c;
      }
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .acknowledgement {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 2px solid #3498db;
  }

  .thank-you {
    font-size: 1.2rem;
    font-weight: bold;
    color: #27ae60;
    text-align: center;
    margin-top: 20px;
  }
}

// Responsive Design
@media (max-width: 768px) {
  .terms-container {
    padding: 15px;

    h1 {
      font-size: 2.3rem;
    }

    .intro {
      font-size: 1.5rem;
    }

    .section {
      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .acknowledgement {
      font-size: 1.2rem;
    }

    .thank-you {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 480px) {
  .terms-container {
    h1 {
      font-size: 2rem;
    }

    .intro {
      font-size: 1rem;
    }

    .section {
      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .acknowledgement {
      font-size: 1rem;
    }

    .thank-you {
      font-size: 1rem;
    }
  }
}
