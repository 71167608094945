.c-container {
    justify-content: space-around;
    gap: 1rem;
}
.c-container>img {
    width: 20rem;
    height: 5rem;
}
.cardtext>h3{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:black;
}