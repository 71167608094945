.store-location-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;

  h1 {
    font-size: 3rem; // Larger heading
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
  }

  .intro {
    font-size: 1.5rem; // Larger intro text
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
  }

  .section {
    margin-bottom: 40px;

    h2 {
      font-size: 2.5rem; // Larger subheading
      font-weight: bold;
      color: #34495e;
      margin-bottom: 20px;
      border-bottom: 2px solid #3498db;
      padding-bottom: 10px;
    }

    p {
      font-size: 1.4rem; // Larger paragraph text
      margin-bottom: 20px;

      strong {
        font-weight: bold;
        color: #e74c3c;
      }
    }
  }

  .map-container {
    margin-top: 20px;
    iframe {
      width: 100%;
      height: 450px;
      border: none;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .thank-you {
    font-size: 1.8rem; // Larger thank-you text
    font-weight: bold;
    color: #27ae60;
    text-align: center;
    margin-top: 40px;
  }
}

// Responsive Design
@media (max-width: 768px) {
  .store-location-container {
    padding: 15px;

    h1 {
      font-size: 2.5rem;
    }

    .intro {
      font-size: 1.3rem;
    }

    .section {
      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .thank-you {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 480px) {
  .store-location-container {
    h1 {
      font-size: 2rem;
    }

    .intro {
      font-size: 1.1rem;
    }

    .section {
      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1rem;
      }
    }

    .thank-you {
      font-size: 1.2rem;
    }
  }
}
