.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 100px;
  height: 100px;
  position: relative;
  transform: rotate(165deg);
}

.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.loader-line-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  animation: spin 2s linear infinite;
}

.loader-line-wrap:nth-child(1) {
  animation-delay: -0.5s;
}

.loader-line-wrap:nth-child(2) {
  animation-delay: -1s;
}

.loader-line-wrap:nth-child(3) {
  animation-delay: -1.5s;
}

.loader-line-wrap:nth-child(4) {
  animation-delay: -2s;
}

.loader-line-wrap:nth-child(5) {
  animation-delay: -2.5s;
}

.loader-line {
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: var(--dark-blue);
  border-radius: 10px;
  transform-origin: center center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// ------------------------------------------

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading {
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotate(165deg);
}

.loading-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.loading-line-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  animation: spinner 2s linear infinite;
}

.loading-line-wrap:nth-child(1) {
  animation-delay: -0.5s;
}

.loading-line-wrap:nth-child(2) {
  animation-delay: -1s;
}

.loading-line-wrap:nth-child(3) {
  animation-delay: -1.5s;
}

.loading-line-wrap:nth-child(4) {
  animation-delay: -2s;
}

.loading-line-wrap:nth-child(5) {
  animation-delay: -2.5s;
}

.loading-line {
  position: absolute;
  width: 10px;
  height: 10%;
  background-color: var(--dark-blue);
  border-radius: 10px;
  transform-origin: center center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
