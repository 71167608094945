.section-more-container {
  max-width: 1200px;
  margin: auto;
  padding: 5rem 1rem;
}
.section-more-header {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #dcab10;
}
.more-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.more-header .section-more-description {
  max-width: 600px;
  color: #fff;
}
.more-btn {
  width: fit-content;
  padding: 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: white;
  white-space: nowrap;
  background-color: #050a30;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
}
.more-btn:hover {
  background-color: #dcab10;
}
.more-content {
  margin-top: 2rem;
  display: grid;
  gap: 2rem;
}
.more-grid {
  display: grid;
  gap: 2rem;
}
.more-image {
  overflow: hidden;
}
.more-image img {
  width: 100%;
  height: auto;
}
.more-card {
  background-color: #050a30;
  padding: 1rem;
  transition: all 0.3s;
  border-radius: 2rem;
}
.more-card:hover {
  background-color: #dcab10;
  transform: translateX(1rem);
}
.more-card h3 {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #dcab10;
}
.more-card h4 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}
.more-card p {
  color: antiquewhite;
  font-weight: 300;
}
.bslides a {
  color: #fff;
}

@media (width > 540px) {
  .more-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width > 768px) {
  .more-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .more-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .more-content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  .more-image {
    grid-area: 1/2/2/3;
  }
}
@media (width > 1024px) {
  .more-grid {
    gap: 4rem 2rem;
  }
}
