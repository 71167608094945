$gap: 20px;
$background-color: #f4f4f4;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: $background-color;
}

.containers {
  width: 100%;
  padding: $gap;
}

.grids {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.grids-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.grids-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.grids-item img:hover {
  transform: scale(1.1);
}
