.product {
  .card {
    padding: 2rem 1rem;
    margin-top: 1rem;
  }
  .review {
    border-top: 1px solid #ccc;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .details {
    padding-top: 2rem;
    display: flex;

    .img {
      width: 50%;
      margin-right: 1rem;
      height: 350px;
      border: 1px solid #ccc;
      border-radius: 3px;
      .pImg {
        width: 100%;
        height: 350px;
        object-fit: cover;
        transform: translateX(3rem);
        animation: slide 0.5s forwards;
      }
    }

    .content {
      width: 50%;
      padding: 0 5px;
      & > * {
        margin-bottom: 1rem;
      }
      .price {
        color: orangered;
        font-weight: 500;
        font-size: 2rem;
      }

      .count {
        display: flex;
        align-items: center;
        & > * {
          margin-right: 1rem;
        }
      }
    }
  }
}

@keyframes slide {
  to {
    transform: translateX(0);
  }
}

.smallImg {
  width: 100%;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-start;

  img {
    // border: 1px solid #ccc;
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    margin-right: 5px;
  }
}

.property {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

@media screen and (max-width: 700px) {
  .product {
    .details {
      flex-direction: column;

      .img {
        width: 100%;
      }

      .content {
        width: 100%;
      }
    }
  }
}

.ratings {
  display: flex;
}
