.deals-container {
  display: grid;
  gap: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 3rem;
}
.deals-card span {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  color: #050a30;
}
.deals-card-content {
  width: 100%;
  height: 100%;
  background-color: #dcab10;
  border-radius: 1rem;
  padding: 1rem;
  transition: all 0.3s;
}
.deals-card-content:hover {
  transform: translateY(-1rem);
  background-color: #6574e3;
  color: antiquewhite;
}
.deals-header-p {
  font-weight: 600;
  color: #dcab10;
}
.deals-card h4 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #050a30;
}
.deals-card-content p {
  font-weight: 600;
}
@media (width > 540px) {
  .deals-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (width > 768px) {
  .deals-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .deals-container:nth-child(1) {
    grid-column: 1/4;
  }
}
@media (width > 1024px) {
  .deals-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .deals-container:nth-child(1) {
    grid-column: unset;
  }
}
