.profile {
  .card {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    border: 1px solid #030b6b;
  }
  .profile-photo {
    width: 100%;
    // border: 1px solid #fff;
    background-color: var(--light-blue);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    & img {
      width: 200px;
      height: 200px;
      border: 2px solid #fff;
      border-radius: 50%;
    }

    & h3 {
      color: #fff;
      font-size: 2rem;
      text-align: center;
    }
  }
  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    textarea,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }

    // textarea {
    //   width: 100%;
    // }
  }
}

.profile_username {
  color: var(--color-yellow);
}
