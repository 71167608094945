.contactUs-container {
  padding: 0;
}
.contactStyle {
  position: relative;
  width: 100%;
  padding: 40px 100px;
}
.contactStyle .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}
.contactStyle .title h2 {
  color: #fff;
  font-weight: 700;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.15);
}
.forms {
  grid-area: form;
}
.infos {
  grid-area: info;
}
.maps {
  grid-area: map;
}
.contacts {
  padding: 40px;
  background: #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.15);
}
.boxes {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 5fr 4fr;
  grid-template-areas:
    "form info"
    "form map";
  grid-gap: 20px;
  margin-top: 20px;
}
.contacts h3 {
  color: #dcab10;
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

/* Form */
.formBoxes {
  position: relative;
  width: 100%;
}
.formBoxes .row1 {
  display: flex;
  gap: 20px;
}
.inputBoxes {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 50%;
}
.formBoxes .row2 .inputBoxes {
  width: 100%;
}
.inputBoxes span {
  color: #050a30;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1.5rem;
}
.inputBoxes input {
  padding: 10px;
  font-size: 1.1em;
  outline: none;
  border: 1px solid #333;
}
.inputBoxes textarea {
  padding: 10px;
  font-size: 1.1em;
  outline: none;
  border: 1px solid #333;
  resize: none;
  min-height: 220px;
  margin-bottom: 10px;
}
.inputBoxes input[type="submit"] {
  background: #050a30;
  color: #fff;
  border: none;
  font-size: 1.1em;
  max-width: 120px;
  font-weight: 500;
  cursor: pointer;
  padding: 14px 15px;
}

.inputBoxes input[type="submit"]:hover {
  background: #dcab10;
}
.inputBoxes ::placeholder {
  color: #999;
}

/* info */

.infos {
  background-color: #050a30;
}
.infos h3 {
  color: #fff;
}
.infos .infoBoxes div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.infos .infoBoxes div span {
  min-width: 40px;
  height: 40px;
  color: #fff;
  background: #dcab10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  border-radius: 50%;
  margin-right: 15px;
}
.infos .infoBoxes div p {
  color: #fff;
  font-size: 1.4em;
}
.infos .infoBoxes div a {
  color: #fff;
  text-decoration: none;
}
.smi {
  margin-top: 40px;
  display: flex;
}
.smi li {
  list-style: none;
  margin-right: 15px;
}
.smi li a {
  color: #fff;
  font-size: 2.5em;
  color: #ccc;
}
.smi li a:hover {
  color: #fff;
}
.maps {
  padding: 0%;
}
.maps iframe {
  width: 100%;
  height: 100%;
}
/* responsiveness */
@media (max-width: 800px) {
  .contactUs .title h2 {
    color: #fff;
  }
  .contactStyle {
    padding: 20px;
  }
  .boxes {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "form"
      "info"
      "map";
  }
  .maps {
    min-height: 300px;
  }
  .formBoxes .row1 {
    display: flex;
    gap: 0;
    flex-direction: column;
  }
  .inputBoxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }
  .contacts {
    padding: 30px;
  }
  .maps {
    padding: 0%;
  }
}
