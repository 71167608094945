img .imrr {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainrr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  background: #03171d;
  overflow: hidden;
  font-family: "Sunflower", serif;
}
.mainrr::before,
.mainrr::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containerrr {
  width: 100%;
}

.swiper {
  width: 100%;
  padding: 60px;
}

.swiper-slide {
  position: relative;
  width: 300px;
  height: 500px;
  background: var(--dark-blue);
  box-shadow: 1px 22px 44px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  transition: 1s;
  user-select: none;
}

.swiper-slide-active {
  width: 600px;
  transition: width 1s ease-in-out;
  border: 3px solid var(--color-white);
}

.swiper-slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire slide */
  filter: grayscale(90%);
}

.swiper-slide-active img {
  filter: grayscale(0%);
}

.titlerr {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 25px 25px 30px;
}

.titlerr h1 {
  font-size: 2rem;
  font-family: "Sunflower", serif;
  font-weight: 700;
  color: var(--color-white);
  opacity: 1;
  transition: 0.8s ease;
  transition-delay: 1s;
}

.swiper-slide-active .titlerr h1 {
  opacity: 0;
  transition-delay: -200ms;
}

.contentrr {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  padding: 25px var(--gap) 65px;
  // gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    180deg,
    transparent,
    transparent,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.7)
  );
  color: var(--color-white);
  opacity: 0;
  z-index: 1;
}

.swiper-slide-active .contentrr {
  opacity: 1;
  transition: 0.8s;
  transition-delay: 0.5s;
}

// text-box

.text-boxrr {
  border-left: 4px solid var(--color-purple);
  padding-left: 10px;
  margin-bottom: 8px;
  opacity: 0;
}

.swiper-slide-active .text-boxrr {
  opacity: 1;
  transition: 0.5s;
  transition-delay: 1s;
}

.text-boxrr p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transform: translateY(100%);
  opacity: 0;
}

.swiper-slide-active .text-boxrr p {
  transform: translateY(0);
  opacity: 1;
  transition: 1.5s ease;
  transition-delay: 1s;
  font-size: 1.5rem;
  color: var(--color-white);
}

// footer

.footerrr {
  // background-color: orange;
  position: absolute;
  bottom: 30px;
  font-size: 2rem;
  left: var(--gap);
  width: calc(100% - var(--gap) * 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
}

.swiper-slide-active .footerrr {
  opacity: 1;
}

.categoryrr {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.categoryrr span {
  color: var(--color-white);
  font-size: 1rem;
  padding: 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
}

.swiper-slide-active .categoryrr span {
  opacity: 1;
  transition: 0.8s ease-out;
  transition-delay: calc(1.5s + var(--i) * 0.4s);
}

.btnrr {
  position: relative;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 8px 15px;
  background: orange;
  color: var(--color-white);
  border: 0;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}

.swiper-slide-active .btnrr {
  opacity: 1;
  transition: 0.8s ease-out;
  transition-delay: 3s;
  font-size: 1rem;
}

.labelrr {
  position: relative;
}

.btnrr::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  transition: 0.5s cubic-bezier(0, 0, 0.2, 1);
  background-color: var(--color-purple);
  border-radius: 50%;
}

.btnrr:hover::before {
  width: 200px;
  height: 200px;
}

.btnrr:hover .labelrr {
  color: var(--dark-blue);
}

// pagination

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background-color: var(--color-white);
}

.swiper-pagination-bullet-active {
  width: 32px;
  border-radius: 14px;
  background-color: orange;
  transition: all 0.8s ease-in-out;
}

// media queries

@media (max-width: 800px) {
  .swiper-slide-active {
    width: 500px;
  }
}
@media (max-width: 650px) {
  .swiper-slide-active {
    width: 400px;
  }
  .text-boxrr p {
    font-size: 1rem;
  }
  .categoryrr span {
    font-size: 1rem;
    padding: 3px 8px;
  }

  .btnrr {
    font-size: 1rem;
    padding: 7px 14px;
  }
}
@media (max-width: 520px) {
  .swiper-slide-active {
    width: 300px;
  }
  .titlerr h1 {
    font-size: 2rem;
  }

  .text-boxrr {
    margin-bottom: 0px;
  }
  .text-boxrr p {
    font-size: 1rem;
    line-height: 1.3;
  }
  .categoryrr {
    gap: 5px;
  }
  .categoryrr span {
    font-size: 1rem;
    padding: 2px 5px;
  }

  .btnrr {
    font-size: 1rem;
    padding: 5px 12px;
  }
}
