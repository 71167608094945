.carouselItem {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 23rem;
  height: 35rem;
  //   margin: auto 1rem;
  margin-right: 5px;
  text-align: center;
  overflow: hidden;

  div.carousel-desc {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.3;
    color: var(--color-dark);
  }
}

.product--image {
  width: 100%;
  height: 65%;

  cursor: pointer;
  object-fit: cover;
}

.price {
  color: red;
  // font-size: 22px;
  span {
    color: #333;
  }
}

// .carouselItem button {
//   border: none;
//   outline: 0;
//   padding: 12px;
//   color: white;
//   background-color: #000;
//   text-align: center;
//   cursor: pointer;
//   width: 100%;
//   font-size: 18px;
// }

// .carouselItem button:hover {
//   opacity: 0.7;
// }
