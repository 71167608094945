.history-hero {
    overflow: hidden;
}
.history-heading h1{
    color: #dcab10;
    font-size: 55px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 0px;
}
.history-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 65px auto;
}
.history-hero-content {
    flex: 1;
    width: 600px;
    margin: 0px 25px;
    animation: fadeInUp 2s ease;
}
.history-hero-content h2{
    font-size: 38px;
    margin-bottom: 20px;
    color: #fff;
}
.history-hero-content button {
    display: inline-block;
    background-color: #dcab10;
    color: #fff;
    padding: 12px 24px;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    transition: 0.3s ease;
}
.history-hero-content button:hover {
    background-color: #050a30;
    transform: scale(1.1);
}
.history-hero-content p{
    font-size: 16px;
    margin-bottom: 20px;
    color:#fff ;
}
.history-image {
    flex: 1;
    width: 600px;
    margin: auto;
}
.history-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    animation: fadeInRight 2s ease;
}

/* MOBILE VIEW */
@media screen and (max-width: 768px) {
    .history-heading h1 {
        font-size: 45px;
        margin-top: 30px;
    }
    .history-hero {
        margin: 0px;
    }
    .history-container {
        width: 100%;
        flex-direction: column;
        margin: 0px;
        padding: 0px 40px;
    }
    .history-hero-content {
        width: 100%;
        margin: 5px 0px;
    }
    .history-hero-content h2 {
        font-size: 24px ;
    }
    .history-heading p{
        font-size: 10px;
        margin-bottom: 20px;
    }
    .history-hero-content button {
        font-size: 12px;
        padding: 8px 16px;
    }
    .history-image{
        width: 100%;
        padding-top: 15px;
    }
}
@keyframes fadeInUp{
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 0;
        transform: translateY(0px);
    }

}
@keyframes fadeInRight{
    0% {
        opacity: 1;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }

}