.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  max-width: 10vh;
  max-height: 10vh;
  z-index: 1000;
}

.spinnerInner {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinnerCircle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #007bff;
  animation: spinner 1.5s linear infinite;

  &:nth-child(2) {
    border-top-color: #ff9a2e;
    animation-delay: -0.75s;
  }

  &:nth-child(3) {
    border-top-color: #ffed2f;
    animation-delay: -0.5s;
  }

  &:nth-child(4) {
    border-top-color: #07031a;
    animation-delay: -0.25s;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
