/* Testimonial Container */

.testimonial-container {
  margin-top: 5rem;
  padding: 2rem;
  background-color: #dcab10;
  border-radius: 3rem;
  box-shadow: rgba(0, 0, 0, 0.5%);
}
.testimonial-slider-container {
  max-width: 800px;
  margin-inline: auto;
  display: flex;
  flex-direction: columns;
  justify-content: center;
  height: 35vh;
}
.testimonial-title {
  text-align: center;
  margin-bottom: 2rem;
}
.testimonial-title h1 {
  font-weight: 600;
  color: #050a30;
}
.testimonial-title p {
  font-weight: 500;
}
.testimonial-item img {
  max-width: 100%;
  display: block;
}

/* slider container */

.testimonial-slider-container {
  position: relative;
  user-select: none;
  padding-inline: 1rem;
}
.testimonial-slider-container .quote {
  position: absolute;
  z-index: -1;
}
.testimonial-slider-container .top-quote {
  position: absolute;
  top: -3rem;
  left: -2rem;
}
.testimonial-slider-container .bottom-quote {
  position: absolute;
  bottom: -1rem;
  right: -0.5rem;
  transform: rotate(180deg);
}

.testimonial-slider-container .testimonial-review-img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
}
.testimonial-slider-container .testimonial-review-content .testimonial-text {
  color: #484646;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.testimonial-slider-container .testimonial-review-content .testimonial-rating {
  display: inline-block;
  line-height: 1;
}
.testimonial-slider-container .testimonial-review-content .testimonial-star {
  color: #ff6f59;
  font-size: 1.5rem;
}
.testimonial-slider-container .testimonial-review-content .testimonial-user {
  font-weight: 600;
}

/* slide customization */

.splide__track {
  border-radius: 1rem;
}
.splide__slide {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 3rem 1.875rem;
  border-radius: 1rem;
  gap: 1rem;
}
.splide__arrow {
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  font-size: 2rem;
  color: #484646;
  z-index: 1;
  font-weight: 300;
}
.splide__arrow--prev {
  left: -3.5rem;
}
.splide__arrow--next {
  right: -3.5rem;
}
.splide__pagination__page {
  height: 10px;
  width: 10px;
  opacity: 1;
}
.splide__pagination__page .is-active {
  background-color: #ff6f59;
}

/* media query */
@media (max-width: 800px) {
  .testimonial-container {
    margin-bottom: 5rem;
  }
  .splide__slide {
    display: block;
    justify-content: center;
    width: 20%;
    text-align: center;
    gap: -4rem;
    background-color: #fff;
    padding-right: 20px;
  }

  .testimonial-slider-container .testimonial-review-img {
    margin-inline: auto;
  }
  .splide__arrow {
    font-size: 1.2rem;
  }
  .splide__arrow--prev {
    left: 0.4rem;
  }
  .splide__arrow--next {
    right: 0.4rem;
  }
  .testimonial-slider-container .bottom-quote {
    right: 0;
  }
  .testimonial-slider-container .testimonial-review-content .testimonial-text {
    color: #484646;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
