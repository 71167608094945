label.uploadWidget {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #444;
  border-radius: 10px;
  width: 100%;
  height: 10rem;
  cursor: pointer;
  font-size: 1.6rem;

  & span {
    font-size: small;
    padding-top: 0.5rem;
  }

  & input {
    display: none;
  }
}
