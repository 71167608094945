.contactbg {
  /* background: linear-gradient(
    90deg,
    #4553bb 0%,
    #4553bb 30%,
    #dcab10 30%,
    #dcab10 100%
  ); */
  background: #000;
  padding: 0;
}
@media (max-width: 780px) {
  .contactbg {
    background: #000;
  }
}
